jQuery(document).ready(function() {

    var exists = function(el, callback) {
        if (el !== null) {
            callback();
        }
        return;
    }

    function setEqualHeight(parent, size, ...elements) {

        if (parent !== undefined && elements.length > 0) {

            var container = new Object;
            container.global = document.querySelector(parent);

            if (container.global) {
                container.elements = [];

                for (var i = 0; i < elements.length; i++) {
                    container.elements.push({
                        height: 0,
                        items: container.global.querySelectorAll(elements[i]),
                    })
                }

                if (window.innerWidth > size) {

                    for (var i = 0; i < container.elements.length; i++) {
                        for (var a = 0; a < container.elements[i].items.length; a++) {

                            container.elements[i].items[a].style.height = "initial";
                            var currentHeight = container.elements[i].items[a].offsetHeight;

                            if (currentHeight > container.elements[i].height) {
                                container.elements[i].height = currentHeight;
                            }

                        }
                    }

                    for (var i = 0; i < container.elements.length; i++) {
                        for (var a = 0; a < container.elements[i].items.length; a++) {
                            container.elements[i].items[a].style.height = container.elements[i].height + "px";
                        }
                    }

                } else {

                    for (var i = 0; i < container.elements.length; i++) {
                        for (var a = 0; a < container.elements[i].items.length; a++) {
                            container.elements[i].items[a].style.height = "initial";
                        }
                    }

                }
            }

        } else {
            console.log("Something went wrong, check arguments that you need to use this function");
        }
    }

    if (document.querySelector('.meetings')) {
        // setEqualHeight('.meetings', 576, 'header', 'li');
    }

    if (document.querySelector('.writers')) {
        // setEqualHeight('.writers', 576, '.content__name');
    }

    if (document.querySelector('.single-writer')) {
        setEqualHeight('.people .schedule ul', 1150, '.date', '.hours', '.place');
    }

    if (document.querySelector('.home')) {
        // setEqualHeight('.home #main', 576, 'li a');
        setEqualHeight('.home #main', 576, 'li a');
    }

    if (document.querySelector('.list-pro')) {
        setEqualHeight('.list-pro', 576, 'li a', 'li .svg', 'li h3', 'li p');
    }

    window.addEventListener('resize', function(e) {
        // setEqualHeight('.meetings', 576, 'header', 'li');

        // setEqualHeight('.writers', 576, '.content__name');

        setEqualHeight('.people .schedule ul', 1150, '.date', '.hours', '.place');

        if (document.querySelector('.list-pro')) {
            setEqualHeight('.list-pro', 576, 'li a', 'li .svg', 'li h3', 'li p');
        }

        // setEqualHeight('.home #main', 576, 'li a');
    })

    // Menu responsive

    let menu = new Object;
    menu = {
        responsive: document.querySelector('.wrap-menumobile'),
        burger: document.querySelector('#burger-menu')
    }

    menu.burger.addEventListener('click', function(e) {
        this.classList.toggle('active');
        menu.responsive.classList.toggle('active');
    })




    // Fire Fancybox plugin
    // jQuery("a.inline").fancybox({
    //     'hideOnContentClick': true
    // });

    // Close Fancybox window to continue editing stand options
    $(".close-fancybox").on('click', function() {
        $.fancybox.close($(this));
    });

    // --- input type file
    // show _FILES name when selected (before upload)
    var inputs = document.querySelectorAll( '.inputfile' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var label     = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
                fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
                label.querySelector( 'span' ).innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });
    });

    // ---- my_info.php (manage exhibitor page)
    // display VAT input on checkbox click
    $("input#exhibitor_subjectedto").on("change", function() {
        $("#vat_number").toggle();
    });

    //JS validate
    $("#billing-info-form").validate({
        rules: {
            exhibitor_name: {
                required: true,
                minlength: 2
            },
            exhibitor_phone: {
                required: true,
                minlength: 10
            },
            exhibitor_email: {
                required: true,
                email: true
            },
            exhibitor_zipcode: {
                minlength: 4
            },
            exhibitor_town: {
                required: true
            },
            exhibitor_country: {
                required: true
            }
        },
        messages: {
            exhibitor_name: "Champ obligatoire",
            exhibitor_phone: "Champ obligatoire, min 10 caractères",
            exhibitor_email: "Veuillez saisir une adresse email valide",
            exhibitor_zipcode: "Champ obigatoire, min 4 caractères",
            exhibitor_town: "Champ obligatoire",
            exhibitor_country: "Champ obligatoire"
        }
    });

    // ---- option_furnitures.php
    // Search in options table
    $("#search").keyup(function() {
        var value = this.value;

        $("table").find("tr").each(function(index) {
            if (index === 0) return;
            var id = $(this).find("td").first().text().toLowerCase().trim();
            $(this).toggle(id.indexOf(value) !== -1);
        });
    });

    // ---- option_form.php
    // delete 0 value on click in the input field
    $(".quantity").on("click", function() {
        if ($(this).val() == "0") {
            $(this).val("");
        };
    });

    // check if the number entered in input area is a round number and not a letter
    $(".quantity").blur(function() {
        if ($(this).val() == "") {
            $(this).val("0");
        };
        if ($(this).val() % 1 != 0) {
            $(this).next().show();
        } else if (!$.isNumeric($(this).val())) {
            $(this).next().show();
        } else {
            $(this).next().hide();
        }
    });

    // calculation in table on load and when you change something
    $(".suboption input").ready(initialInputs);
    $(".suboption input").keyup(multInputs);
    $(".new-suboption input").ready(initialInputs);
    $(".new-suboption input").keyup(multInputs);

    function initialInputs() {
        var mult = 0;
        // for each row:
        $("tr.suboption").each(function() {
            var qty_initial = 0;
            var price_initial = 0;

            if ($(this).find('.existing_qty').length) {
                qty_initial = parseFloat($('.existing_qty', this).val());
                price_initial = parseFloat($('.existing_price', this).val());
            } else if ($(this).find('.free_quantity').length) {
                qty_initial = parseFloat($('.free_quantity', this).val());
            }

            $('.quantity', this).val(qty_initial);
            $('.total', this).val(price_initial);
            mult += price_initial;

        });

        $("tr.new-suboption").each(function() {
            if($('.new-quantity', this).val() == '') { $('.new-quantity', this).val(0); }
            if($('.new-price', this).val() == '') { $('.new-price', this).val(0); }
            if($('.new-total', this).val() == '') { $('.new-total', this).val(0); }
        });

        $("#final_total").val(parseFloat(mult).toFixed(2));
    }

    function multInputs() {
        var mult = 0;
        // for each row:
        $("tr.suboption").each(function() {
            var qty_initial = parseFloat($('.quantity', this).val());
            var qty_free = parseFloat($('.free_quantity', this).val());

            var quantity = qty_initial - qty_free;

            if ($('.quantity', this).val() == '') {
                if (qty_free > 0) {
                    quantity = qty_free;
                } else {
                    quantity = 0;
                }
                $('.quantity', this).val(quantity);
            }

            if ($('.price', this).val().indexOf(',') > -1) {
				var price = parseFloat($('.price', this).val().replace(',', '.'));
			} else {
				var price = parseFloat($('.price', this).val());
			}

            // var total_initial = parseFloat($('.total', this).val());
            var total = 0 + (parseFloat(quantity) * parseFloat(price));
            if (total < 0) {
                $('.quantity', this).val(qty_initial + qty_free);
                total = 0;
            }

            $('.total', this).val(total.toFixed(2));
            mult += total;
        });

        $("tr.new-suboption").each(function() {
            var quantity = parseFloat($('.new-quantity', this).val());

            if ($('.new-quantity', this).val() == '') {
                quantity = 0;
                $('.new-quantity', this).val(quantity);
            }

            if ($('.new-price', this).val().indexOf(',') > -1) {
				var price = parseFloat($('.new-price', this).val().replace(',', '.'));
			} else {
				var price = parseFloat($('.new-price', this).val());
			}

            if ($('.new-price', this).val() == '') {
                price = 0;
                $('.new-price', this).val(price);
            }

            var total = 0 + (parseFloat(quantity) * parseFloat(price));

            $('.new-total', this).val(total.toFixed(2));
            mult += total;
        });

        $("#final_total").val(parseFloat(mult).toFixed(2));
    }

    // ---- option_clark.php
    // calculation for 1 free when you buy 1
    $(".invitation-cards input").ready(initialInputsCards);
    $(".invitation-cards input").keyup(multInputsCards);

    function initialInputsCards() {
        var mult = 0;
        var qty_initial = 0;
        var qty_initial_given = 0;
        var price_initial = 0;

        $("tr.cartons-dinvitation").each(function() {
            if ($(this).find('.existing_qty').length) {
                qty_initial = parseFloat($('.existing_qty', this).val());
                price_initial = parseFloat($('.existing_price', this).val());
            } else if ($(this).find('.free_quantity').length) {
                qty_initial = parseFloat($('.free_quantity', this).val());
            }

            $('.quantity', this).val(qty_initial);
            $('.total', this).val(price_initial);
		});

        $("tr.cartons-dinvitation-offerts").each(function() {
			if ($(this).find('.existing_qty').length) {
				qty_initial_given = parseFloat($('.existing_qty', this).val());
			}
            $('.quantity', this).val(qty_initial_given);
            $('.total', this).val(0);
		});

        $("#cards_final_total").val(parseFloat(price_initial));
    }

    function multInputsCards() {
        var mult = 0;
		var quantity;

		$("tr.cartons-dinvitation").each(function() {
			var qty_initial = parseFloat($('.quantity', this).val());
			var qty_free = parseFloat($('.free_quantity', this).val());

			quantity = qty_initial - qty_free;

			if ($('.quantity', this).val() == '') {
				if (qty_free > 0) {
					quantity = qty_free;
				} else {
					quantity = 0;
				}
				$('.quantity', this).val(quantity);
			}

			if ($('.price', this).val().indexOf(',') > -1) {
				var price = parseFloat($('.price', this).val().replace(',', '.'));
			} else {
				var price = parseFloat($('.price', this).val());
			}

			// var total_initial = parseFloat($('.total', this).val());
			var total = 0 + (parseFloat(quantity) * parseFloat(price));
			if (total < 0) {
				$('.quantity', this).val(qty_initial + qty_free);
				total = 0;
			}

			$('.total', this).val(total.toFixed(2));
			mult += total;
		});

		$("tr.cartons-dinvitation-offerts").each(function() {
			$('.quantity', this).val(quantity);
		});

		$("#cards_final_total").val(parseFloat(mult).toFixed(2));
    }


    // ---- stand_form.php
    // green border when checkbox is checked in stand_form template
    $('input[name=pick_stand]:checked').parent().addClass('checked');
    $("input[name='pick_stand']").on("change", function() {
        $('.checked').removeClass('checked');
        $('input[name=pick_stand]:checked').parent().addClass('checked');
        $(this)
    });

    // display registered stand technical specifications on load
    $('form input[name="pick_stand:checked"]').ready(function(){
        var stand_id = $("form input[type='radio']:checked").val();
        var order_id = $("form input[type='radio']:checked").data('order');

        jQuery.ajax({
            type: "POST",
            url: ajaxurl,
            data: {
                'action': 'get_chosen_stand_specs',
                'stand_id': stand_id,
                'order_id': order_id,
            },
            success: function(result){
                $('.spec-tech').html(result);
            },
            error: function(result, status, error){
                $('.spec').html('<div class="red">Un problème est survenuEEE : ' + error + '</div>');
            }
        });
    });

    // display technical specifications when you pick (or change) a stand
    $('form input[name="pick_stand"]').change(function(){
        var stand_id = $("form input[type='radio']:checked").val();
        var order_id = $("form input[type='radio']:checked").data('order');

    	jQuery.ajax({
			type: "POST",
			url: ajaxurl,
			data: {
				'action': 'get_chosen_stand_specs',
		        'stand_id': stand_id,
		        'order_id': order_id,
			},
			success: function(result){
				$('.spec-tech').html(result);
			},
			error: function(result, status, error){
	            $('.spec').html('<div class="red">Un problème est survenu : ' + error + '</div>');
	        }
		});
    });


    // --- stand_configuration.php
    // Verif if final quote has been downloaded
    $('#download-quote .dkpdf-button').click(function(){

    	var order_id = $('#download-quote').data('order');

        // Requête Ajax
        $.ajax({
            type: "GET",
            url: ajaxurl,
            data: ({ action: 'verif_download_quote', order_id: order_id }),
            datatype: 'json',
            success: function(data){

            },
            error: function(data){

            }
        });
    });


    //ADD and DELETE icons
    $(document).on("click", ".sm-icon-add", function(){
        $(this).removeClass("sm-icon-add").addClass("sm-icon-delete");
        $.ajax({
            type: "GET",
            url: ajaxurl,
            data: ({action: 'show_specialties'}),
            success: function(results){
            $('.select-wrappers').append(results);
            },
            error: function(results){
            }
        });
    });

    $(document).on("click", ".sm-icon-delete", function(){
        $(this).prev().remove();
        $(this).remove();
    });



    function readPath(input) {

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                var image = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;

                //Validate the File Height and Width.
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    var fileExtension = ['jpg', 'png'];

                    //add to condition: || $.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1
                    if (height > 400 || width > 400 ) {
                        $('.photo-requirements').css('color', '#CE0000').css('font-weight', '500').css('font-size', '24px');
                        $('button[type=submit]').hide();
                        $('.image_onchange').attr('src', e.target.result);
                        return false;
                    } else {
                        $('.image_onchange').attr('src', e.target.result);
                        $('.photo-requirements').css('color', '#333333').css('font-weight', '100').css('font-size', '12pt');
                        $('button[type=submit]').show();
                    }
                };
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    $("#avatar").change(function(){
        readPath(this);
    });

    // have the "datepicker" class set
    // $('input.datepicker').Zebra_DatePicker({
    //     format :"d m Y"
    // });
    // var datepicker = $('input').data('Zebra_DatePicker');
    // datepicker.update({
    //     direction: 1
    // });
    
    
      $(".dkpdf-button").click(function(){setTimeout(function() {
     location.reload()
      },5000);
        });

    // $('.owl-carousel').owlCarousel();

    // console.log($('.sponsors ul'))

});
